<template>
  <div class="comment-wrapper">

    <span ref="comment" v-show="false">
      <slot />
    </span>

    <span class="comment-small" ref="hiddenText" v-show="isHidden" v-html="commentText" />
    <span class="comment-all" ref="allText" v-show="!isHidden" v-html="commentText" />

    <p @click="isHidden = !isHidden"
       v-if="needCollapse"
       class="comment-toggle">
      <span v-if="isHidden">{{ $t('readMore') }}</span>
      <span v-if="!isHidden">{{ $t('hide') }}</span>
    </p>
  </div>

</template>

<script>
export default {
  name: 'Comment',
  data () {
    return {
      isHidden: false,
      needCollapse: false,
      commentText: '',
      lineHeight: '16px',
    }
  },
  mounted () {
    const commentRef = this.$refs.comment
    this.commentText = commentRef.innerText
    this.lineHeight = getComputedStyle(commentRef).lineHeight

    this.$nextTick(() => {
      this.checkComment()
    })
  },
  methods: {
    checkComment () {
      const hiddenText = this.$refs.allText

      if (hiddenText.scrollHeight > parseInt(this.lineHeight)) {
        this.isHidden = true
        this.needCollapse = true
      }
    },
  },
}
</script>
<style lang="scss" scoped>

$font-size: 14px;
$line-height: 1.2;
$lines-to-show: 2;

.comment {
  width: 100%;
  height: 40px;
  display: inline-block;
  text-overflow: ellipsis;
  position: relative;
  line-height: $line-height;

  &-wrapper {
    @include flex(column)
  }

  &-small {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
    margin: 0 auto;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-toggle {
    border: none;
    background: transparent;
    border-bottom: 0.7px dashed $text-gray;
    color: $text-gray;
    cursor: pointer;
    padding: 5px 0 0;
    display: inline-block;
    width: fit-content;
    font-style: normal;
  }
}
</style>
