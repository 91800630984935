<template>
  <base-select
    :value="value"
    :options="pagination"
    :clearable="false"
    :searchable="false"
    @input="handleInput"
  />
</template>

<script>
import BaseSelect from 'smarket-ui/src/components/select/BaseSelect'

export default {
  props: ['value'],
  components: {
    BaseSelect,
  },
  data () {
    return {
      pagination: [20, 40, 100],
    }
  },
  methods: {
    handleInput (value) {
      this.$emit('input', value)
    },
  },
}
</script>
