<template>
  <div class="main-content">
    <base-bread-crumbs />
    <div class="main-content__inner">

      <template v-if="profileStatus === 'approved'">
        <div class="page-title page-title--billing">{{ $t('payments') }}</div>

        <div class="billing-filters">
          <div class="dates">
            <BaseDatepicker v-model="dateFrom" :placeholder="$t('datepicker.from')" :disabled-date="disableDateFrom" />
            <span class="dates-sep">–</span>
            <BaseDatepicker v-model="dateTo" :placeholder="$t('datepicker.to')" :disabled-date="disableDateTo" />
            <button class="dates-reset" v-if="dateTo || dateFrom" @click="resetDates">
              <span>{{ $t('datepicker.reset') }}</span>
              <svg-icon icon-name="close" />
            </button>
          </div>
          <div class="billing-pagination-select">
            <p>{{ $t('paymentsOnPage') }}:</p>
            <PaginationSelect :value="pagination.per_page" @input="updatePerPage($event)" />
          </div>
        </div>

        <div class="billing-table" v-if="!loading && payments.length > 0">
          <BaseTable>
            <template #header>
              <th>{{ $t('billingTable.number') }}</th>
              <th>{{ $t('billingTable.period') }}</th>
              <th>{{ $t('billingTable.payments') }}</th>
              <th>{{ $t('billingTable.comment') }}</th>
              <th>{{ $t('billingTable.total') }}</th>
            </template>
            <template #body>
              <tr v-for="item in payments" :key="item.trackNumber">
                <td class="table-number">
                  <p>{{ item.paymentNumber }}</p>
                  <p>{{ item.finishedAt }}</p>
                </td>
                <td class="table-period">
                  {{ item.paymentInterval }}
                </td>
                <td class="table-payments">
                  <div class="table-payments-content">
                    <strong>{{ $t('orders') }} <span>({{ item.orderCount }})</span></strong>
                    <router-link :to="`/billing/${item.paymentNumber}`">
                      <button class="check-payment">{{ $t('viewDetails') }}</button>
                    </router-link>
                  </div>
                </td>
                <td class="table-comment">
                  <Comment class="table-comment__content">{{ item.comment }}</Comment>
                  <div class="table-files" v-if="item.files.length">
                    <FileDownload
                      class="file"
                      v-for="file of item.files"
                      :key="file.path"
                      :file="file"
                    />
                  </div>
                </td>
                <td class="table-total">
                  ${{ item.totalSum }}
                </td>
              </tr>
            </template>
          </BaseTable>
        </div>

        <div v-if="!loading && payments.length === 0" class="billing-empty">
          <template v-if="dateFrom || dateTo">{{ $t('noPaymentsByInterval') }}</template>
          <template v-else>{{ $t('noPayments') }}</template>
        </div>
      </template>

      <template v-else>
        <ProfileApprovedMsg>
          {{ $t('paymentsVerifiedMessage') }}
        </ProfileApprovedMsg>
      </template>

      <br>

      <div class="billing-filters" v-if="profileStatus === 'approved'">
        <div>
          <ListPagination
            v-if="pagination.pages > 1"
            class="billing-pagination"
            :pagination="pagination"
            :withLabel="true"
            @onSelectPage="updatePageIndex($event)"
          />
        </div>

        <div class="billing-pagination-select">
          <p>{{ $t('paymentsOnPage') }}:</p>
          <PaginationSelect :value="pagination.per_page" @input="updatePerPage($event)" />
        </div>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import BaseBreadCrumbs from '@/components/ui/layout/BaseBreadCrumbs'
import BaseDatepicker from '@/components/ui/datepicker/BaseDatepicker'
import BaseTable from '@/components/ui/tables/BaseTable'
import FileDownload from '@/components/pages/billing/FileDownload'
import PaginationSelect from '@/components/ui/forms/select/PaginationSelect'
import ListPagination from '@/components/ui/tables/pagination/ListPagination'
import Comment from '@/components/pages/billing/Comment'
import { mapState } from 'vuex'
import ProfileApprovedMsg from '@/components/ui/notify/ProfileApprovedMsg'

export default {
  name: 'BillingPage',
  components: {
    ProfileApprovedMsg,
    Comment,
    ListPagination,
    FileDownload,
    PaginationSelect,
    BaseTable,
    BaseDatepicker,
    BaseBreadCrumbs,
  },
  data () {
    return {
      dateFrom: null,
      dateTo: null,
      loading: true,
    }
  },
  computed: {
    ...mapState({
      payments: state => state.billing.payments,
      pagination: state => state.billing.pagination,
      profileStatus: state => state.user.profile.status,
    }),
  },
  methods: {
    async getData () {
      this.$store.commit('setLoadingStatus')
      const params = this.createParams()
      await this.$store.dispatch('getPayments', { params })
      this.loading = false
      this.$store.commit('setSuccessStatus')
    },
    resetDates () {
      this.dateFrom = null
      this.dateTo = null
    },
    createParams () {
      return {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        perPage: this.pagination.per_page,
        page: this.pagination.page,
      }
    },
    updatePageIndex (page) {
      const pagination = { ...this.pagination, page }
      this.$store.dispatch('updatePagination', { pagination })
      this.getData()
    },
    updatePerPage (perPage) {
      const pagination = { ...this.pagination, per_page: perPage }
      this.$store.dispatch('updatePagination', { pagination })
      this.getData()
    },
    disableDateTo (date) {
      return date <= this.dateFrom
    },
    disableDateFrom (date) {
      if (this.dateTo) {
        return date >= this.dateTo
      } else {
        return false
      }
    },
  },
  created () {
    this.getData()
  },
  watch: {
    dateFrom () {
      this.getData()
    },
    dateTo () {
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
$gutter: 20px;
$color-dark: #333;

.page-title {
  &--billing {
    margin: 0;
    border: none;
    min-height: auto;
  }
}

.billing {
  &-filters {
    margin: $gutter 0;
    @include flex(row, space-between, center);

    .dates {
      display: grid;
      align-items: center;
      grid-template-columns: 140px 10px 140px auto;
      grid-gap: $gutter / 2;

      :deep(input) {
        height: 42px;
      }

      &-sep {
        line-height: 1;
      }

      .mx-datepicker {
        width: 100%;
      }

      &-reset {
        border: none;
        background: transparent;
        color: $text-gray;
        @include flex(row, center, center);
        cursor: pointer;
        font-size: 14px;

        &:hover {
          text-decoration: underline;
        }

        svg {
          padding-left: 4px;
          width: 10px;
        }
      }
    }
  }

  &-pagination {
    padding: 0;

    &-select {
      @include flex(row, flex-end, center);

      p {
        color: $text-gray;
        padding-right: $gutter;
      }
    }
  }

  &-table {
    color: $text-gray;
    font-size: 14px;
    line-height: 1.2;

    th {
      &:last-child {
        padding-right: $gutter;
      }
    }

    td {
      &:last-child {
        padding-right: $gutter;
      }
    }
  }

  &-empty {
    min-height: 56px;
    @include flex(row, flex-start, center);
    background-color: $color-active-blue;
    padding: $gutter;
    box-sizing: border-box;
    margin-top: $gutter;
  }
}

.table {
  &-number {
    font-weight: lighter;

    p {
      &:first-child {
        color: $color-primary;
      }
    }
  }

  &-payments {

    &-content {
      @include flex(column, flex-start, flex-start);
      text-align: left;

      strong {
        color: $color-dark;
        font-size: 14px;

        span {
          font-weight: normal;
          color: $text-gray;
        }
      }
    }
  }

  &-period {
    color: $color-dark;
  }

  &-total {
    font-weight: bold;
    color: $color-dark;
  }

  &-comment {
    max-width: 440px;

    &__content {
      font-style: italic;
      line-height: 16px;
    }
  }

  &-files {
    padding: 10px 0;

    .file {
      &:not(:last-child) {
        padding-right: $gutter / 2;
      }
    }
  }
}

button.check-payment {
  border: none;
  background: transparent;
  border-bottom: 0.7px dashed $text-gray;
  color: $text-gray;
  cursor: pointer;
  padding: 4px 0 0;
}
</style>
