<template>
  <div class="message">
    <p>
      <slot />
    </p>

    <router-link to="/profile" class="message-link">
      <BaseButton>{{$t('goToProfile')}}</BaseButton>
    </router-link>
  </div>
</template>

<script>
import BaseButton from '@/components/ui/buttons/BaseButton'

export default {
  name: 'ProfileApprovedMsg',
  components: {
    BaseButton,
  },
}
</script>

<style lang="scss" scoped>
$gutter: 20px;

.message {
  p  {
    min-height: 56px;
    @include flex(row, flex-start, center);
    background-color: $color-active-blue;
    padding: $gutter;
    box-sizing: border-box;
    margin-top: $gutter;
    margin-bottom: $gutter;
  }

  &-link {
    text-decoration: none;
  }
}
</style>
