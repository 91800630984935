<template>
  <DatePicker
    v-model="value"
    :lang="defaultLang"
    :format="defaultFormat"
    :disabled-date="disabledDate"
    :placeholder="placeholder || 'select date'"
    popup-class="base-datepicker hide-double-arrows"
    input-class="input input--datepicker"

    @input="$emit('input', value)"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    @open="$emit('open', $event)"
    @close="$emit('close', $event)"
    @confirm="$emit('confirm', $event)"
    @pick="$emit('pick', $event)"
    @clear="$emit('clear', $event)"
    @input-error="$emit('input-error', $event)"
    @calendar-change="$emit('calendar-change', $event)"
    @panel-change="$emit('panel-change', $event)"
  >
    <template slot="icon-calendar">
      <svg-icon class-name="datepicker-icon" icon-name="calendar" />
    </template>
  </DatePicker>
</template>

<script>
import DatePicker from 'vue2-datepicker'

export default {
  components: {
    DatePicker,
  },
  name: 'BaseDatepicker',
  props: ['model', 'lang', 'format', 'disabledDate', 'placeholder'],
  model: {
    prop: 'model',
    event: 'input',
  },
  data () {
    return {
      value: this.model,
      defaultFormat: this.format || 'DD.MM.YYYY',
      defaultLang: this.lang || {
        monthFormat: 'MMMM',
      },
    }
  },
  watch: {
    model (v) {
      this.value = v
    },
  },
}
</script>

<style lang="scss" scoped>
.mx-datepicker .mx-icon-calendar {
  right: 20px;

  svg {
    fill: $color-disabled;
  }
}

:deep(i.mx-icon-calendar) {
  right: 20px;
}

:deep(i.mx-icon-clear) {
  right: 20px;
}

.mx-datepicker {
  width: auto;
}

:deep(.base-datepicker) {
  background: #000;

  .mx-calendar-header {
    grid-template-areas: "date prev next";
  }
}

:deep(.input) {
  &--datepicker {
    height: 42px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $color-line;
    max-width: 140px;

    outline-color: $color-primary;
    outline-width: 1px;
    padding: 0 20px;

    &:focus,
    &:hover {
      border: 1px solid $color-primary;
    }
  }
}
</style>
