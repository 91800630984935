<template>
  <div class="file-download" @click="saveFile">
    <div class="file-icon">
      <svg-icon class-name="file-icon" :icon-name="filename" />
    </div>
    <div class="file-description">
      <p class="action">{{$t('download')}}</p>
      <p class="name">{{ sliceName(file.name) }}</p>
    </div>
  </div>
</template>

<script>
import api from '@/api/api'

const availableFiles = ['jpeg', 'jpg', 'png', 'pdf', 'xml', 'zip']

export default {
  name: 'FileDownload',
  props: ['file'],
  methods: {
    sliceName (name) {
      return name.length > 10 ? name.slice(0, 8) + '...' : name
    },
    async saveFile () {
      try {
        const file = await this.loadFile()
        const a = document.createElement('a')
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = file.name
        document.body.appendChild(a)
        a.click()
        setTimeout(() => {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        }, 0)
      } catch (e) {
        await this.$store.dispatch('showError', e)
      }
    },
    async loadFile () {
      try {
        const res = await api.getFile(this.cleanedPath)
        return new File([res], this.file.name)
      } catch (e) {
        if (e.message && e.message.includes('404')) {
          throw new Error(this.$t('fileNotFound'))
        } else if (e.message) {
          throw new Error(e.message)
        } else {
          throw new Error(this.$t('copyErrorTitle'))
        }
      }
    },
  },
  computed: {
    filename () {
      const isAvailable = availableFiles.includes(this.file.extname)
      return isAvailable ? this.file.extname : 'file'
    },
    cleanedPath () {
      return this?.file?.path?.replace('api/v1', '')
    },
  },
}
</script>

<style scoped lang="scss">
.file {
  &-download {
    @include flex(row, flex-start, center);
    display: inline-flex;
    height: 32px;
    padding: 2px;
    cursor: pointer;

    &:hover {
      .file-icon {
        fill: #3CD4D1
      }
    }
  }

  &-description {
    @include flex(column, flex-start);

    .action {
      font-weight: bold;
      color: $color-primary;
      letter-spacing: -1px;
      font-size: 14px;
    }

    .name {
      font-size: 10px;
      color: $text-gray;
    }
  }

  &-icon {
    fill: $color-primary;
    height: 100%;
    min-width: 22px;
    padding-right: 2px;

    transition: .2s ease all;
  }
}

</style>
